import React from "react"
import styled from "styled-components"

import Layout from "../../components/layout-canada"
import SEO from "../../components/seo"
import Sarsons from "../../images/sarsons.jpeg"
import LeafBorder from "../../images/hand-painted-leaf-border-cut.png"

const Picture = styled.div`
  background-image: url("${Sarsons}");
  width: 100%;
  height: 400px;
  background-position: 40%;
  margin-bottom: 42px;
  @media (max-width: 768px) {
    height: 200px;
    background-size: auto 200px;
    background-repeat: no-repeat;
  }
`

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 270px;
  margin-bottom: 32px;
  background-size: 270px 30px;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

const H4 = styled.h4`
  font-family: "Benton Sans";
  margin-bottom: 1.45rem;
`

const Subheader = styled.h3`
  font-family: "Benton Sans";
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const Tab = styled.span`
  padding: 0 15px;
`

class FridayPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Friday | Sara & Tim" />
        <H1>FRIDAY, JUNE 10TH</H1>
        <LeafBorderPicture />
        <Picture></Picture>
        <Subheader>Walk and Beach BBQ</Subheader>
        <H4>Note: These plans are a rough indication and subject to change</H4>
        <P>
          <a href="https://www.kelowna.ca/parks-recreation/parks-beaches/parks-beaches-listing/knox-mountain-park">
            Knox Mountain Park
          </a>{" "}
          provides stunning views over Kelowna and it's iconic lake, which makes
          for a great place to walk. We'll aim to hike to Paul's tomb, which is
          an easy-to-moderate walk (stroller-friendly) and should take less than
          2 hours (4 km), but the route can easily be modified for anyone
          looking for a gentler time.
        </P>
        <P>
          Afterwards, we'll get a closer look at that lake, from one of the
          beautiful, sandy beaches that line the shores. It's short 20-minute
          drive to{" "}
          <a href="https://www.kelowna.ca/parks-recreation/parks-beaches/parks-beaches-listing/sarsons-beach-park">
            Sarson's Beach
          </a>
          , where we can BBQ, relax and swim, hopefully basking in the early
          summer sun!
        </P>
        <P>
          11:00<Tab></Tab>Meet at Knox mountain car park
        </P>
        <P>
          From 13:30<Tab></Tab>Beach time!
        </P>
      </Layout>
    )
  }
}

export default FridayPage
